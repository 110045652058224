<template>
  <el-container>
    <el-main style="padding-left: 0;padding-right: 0">
      <div class="container" style="padding-left: 5px; padding-right: 5px">
        <el-row type="flex" justify="center">
          <!--          &lt;!&ndash;左边栏&ndash;&gt;-->
          <!--          <el-col :span="4">-->
          <!--            <div class="grid-content bg-purple">-->
          <!--              <el-card class="box-card">-->
          <!--                <div slot="header" class="clearfix">-->
          <!--                  <span>卡片名称</span>-->
          <!--                  <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
          <!--                </div>-->
          <!--                <div v-for="o in 4" :key="o" class="text item">-->
          <!--                  {{ '列表内容 ' + o }}-->
          <!--                </div>-->
          <!--              </el-card>-->
          <!--            </div>-->
          <!--          </el-col>-->
          <!--中间栏-->
          <el-col :xs="24" :sm="22" :md="18" :lg="16" :xl="16">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div class="profile">
                  <div class="profile-left" style="margin-right: 5px">
                    <el-avatar size="medium" :src="discussData.user_figureurl_qq_1"></el-avatar>
                  </div>
                  <div class="profile-middle">
                    <h4>{{ discussData.user_nickname }}</h4>
                    <div class="profile-time">{{ discussData.create_time.substring(0, 10) }}</div>
                  </div>
                  <div class="profile-right">
                    <el-button icon="el-icon-document-copy" size="mini" @click="copyLineupID">复制ID
                    </el-button>
                    <el-button icon="el-icon-link" size="mini" @click="copyLineupLink">复制链接
                    </el-button>
                    <!--                    <el-tooltip class="item" effect="dark" content="复制链接" placement="top">-->
                    <!--                      <el-button icon="el-icon-link" circle @click="copyLineupLink"></el-button>-->
                    <!--                    </el-tooltip>-->
                    <el-button type="primary" size="mini" @click="openSetLineupLink"
                               v-if="$store.state.userinfo.isAdmin">lineup
                    </el-button>
                    <el-popconfirm
                        v-if="$store.state.userinfo.isAdmin"
                        title="确定删除lineup吗？"
                        @confirm="delLineup"
                    >
                      <el-button slot="reference" type="danger" size="mini" style="margin-left: 5px">unline</el-button>
                    </el-popconfirm>
                    <el-button type="primary" size="mini" @click="openEditBlog"
                               v-if="$store.state.userinfo.isAdmin"
                               style="margin-left: 5px"
                    >编辑
                    </el-button>
                    <el-popconfirm
                        v-if="$store.state.userinfo.isAdmin"
                        title="确定删除吗？"
                        @confirm="delBlog"
                    >
                      <el-button slot="reference" type="danger" size="mini" style="margin-left: 5px">删除</el-button>
                    </el-popconfirm>
                  </div>
                </div>

                <div class="detail" style="font-size: 16px">
                  <div class="detail-title">
                    {{ discussData.title }}
                  </div>
                  <div class="detail-content">
                    {{ discussData.content }}
                  </div>
                </div>

                <div class="images" v-show="imageList.length !== 0" id="images">
                  <template v-for="(item, index) in imageList">
                    <!--                    <el-image-->
                    <!--                        style="margin-top: 10px"-->
                    <!--                        :src="item"-->
                    <!--                        :preview-src-list="imageList"-->
                    <!--                        :key="index"-->
                    <!--                    >-->
                    <!--                    </el-image>-->
                    <img class="fangda"
                         :src="item"
                         :key="index" width="100%">
                  </template>

                </div>
                <div v-if="videoSrc" style="margin-top: 20px;font-size: 16px">
                  <template v-if="discussData.bili_start_minute===0 && discussData.bili_start_second===0">
                    视频地址：
                  </template>
                  <template v-else>
                    视频地址(精准空降{{ discussData.bili_start_minute }}分{{ discussData.bili_start_second }}秒)：
                  </template>
                  <a :href="linkWithSecond" target="_blank">{{ linkWithSecond }}</a>
                  <div class="biliVideo">
                    <iframe :src="this.videoSrc" allowfullscreen="allowfullscreen"></iframe>
                  </div>
                </div>

              </el-card>
              <!--发布评论-->
              <el-card class="box-card" style="margin-top: 10px">
                <!--              <el-card class="box-card" style="margin-top: 10px" @click.native="checkIsLogin($event)">-->
                <div class="comment">
                  <div class="comment-left">
                    <el-avatar size="medium" :src="$store.state.userinfo.figureurl_qq_1"></el-avatar>
                  </div>
                  <el-input
                      type="textarea"
                      autosize
                      placeholder="评论（无需登录）"
                      v-model="comment">
                  </el-input>
                </div>
                <!--底部按钮-->
                <div class="posting-bottom-bar">
                  <div class="left">
                  </div>
                  <div class="right">
                    <el-button type="primary" size="medium" round @click="submitComment">发布</el-button>
                  </div>
                </div>
              </el-card>
              <!--评论列表-->
              <el-card class="box-card" style="margin-top: 10px" v-if="commentList.length!==0"
                       :body-style="{ padding: 0 }">
                <div slot="header" class="clearfix">
                  <span style="font-weight: 600">全部评论({{ commentList.length }})</span>
                </div>
                <template v-for="(item, index) in commentList">
                  <div :key="index" class="discuss-data-item">
                    <div class="profile" style="margin-bottom: 0">
                      <div class="profile-left" style="margin-right: 5px">
                        <el-avatar size="medium" :src="item.figureurl_qq_1"></el-avatar>
                      </div>
                      <div class="profile-middle">
                        <h4>{{ item.nickname ? item.nickname : '匿名用户' }}</h4>
                        <div class="profile-time">{{ item.create_time.substring(0, 10) }}</div>
                      </div>
                      <el-popconfirm
                          v-if="$store.state.userinfo.isAdmin"
                          title="确定删除吗？"
                          @confirm="delComment(item.id)"
                      >
                        <el-button slot="reference" type="text">删除</el-button>
                      </el-popconfirm>
                    </div>
                    <div style="font-size: 15px">
                      <!--                      {{ item.content }}-->
                      <div v-html="replaceSrc(item.content)"></div>
                    </div>
                  </div>
                  <el-divider :key="index"></el-divider>
                </template>
              </el-card>
            </div>
          </el-col>
          <!--          &lt;!&ndash;          右边栏&ndash;&gt;-->
          <!--          <el-col :span="4">-->
          <!--            <div class="grid-content bg-purple">-->
          <!--              <el-card class="box-card">-->
          <!--                <div slot="header" class="clearfix">-->
          <!--                  <span>卡片名称</span>-->
          <!--                  <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
          <!--                </div>-->
          <!--                <div v-for="o in 4" :key="o" class="text item">-->
          <!--                  {{ '列表内容 ' + o }}-->
          <!--                </div>-->
          <!--              </el-card>-->
          <!--            </div>-->
          <!--          </el-col>-->
        </el-row>
      </div>
    </el-main>
    <el-dialog
        title="登录LKVAL"
        :visible.sync="loginDialogVisible"
        width="30%">
      <div class="header-btn">
        <el-button type="primary" size="small" @click="openQQLogin">
          QQ登录
        </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import Viewer from 'viewerjs'
import axios from 'axios'
import {biliLinkToIframe} from "@/common/bili";
import {imgNameToUrl} from "@/common/comm";
import store from "@/store";

function replaceSrc(txt) {
  // var reg =
  //   /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi;
  let reg =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Za-z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Za-z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Za-z0-9+&@#/%=~_|$?!:,.]*\)|[A-Za-z0-9+&@#/%=~_|$])/gi;
  return txt.replace(reg, function (item) {
    return "<a href='" + item + "' target='_blank'>" + item + "</a>";
  })
}

export default {
  name: "DiscussDetail",
  data() {
    return {
      discussData: {},
      imageList: [],
      videoSrc: '',
      linkWithSecond: '',
      comment: '',
      commentList: [],
      loginDialogVisible: false,
      replaceSrc
    }
  },
  mounted() {
    this.getDiscussDetail(this.$route.params.blogId, this.$route.params.lineupId)
  },
  methods: {
    getDiscussDetail(blogId, lineupId) {
      axios({
        method: 'get',
        url: '/api/blogs/get_discuss_detail',
        params: {
          blog_id: blogId,
          lineup_id: lineupId,
        }
      }).then(r => {
        if (r.data.code !== 0) {
          this.$message.error(r.data.msg);
          return
        }
        // 获取评论
        this.getCommentList(this.$route.params.blogId)
        this.discussData = r.data.detail
        // 处理标题
        if (this.discussData.title) {
          document.title = this.discussData.title
        } else {
          document.title = '帖子详情'
        }

        // 处理图片
        for (let i = 0; i < this.discussData.images.length; i++) {
          // 腾讯云COS
          // this.imageList.push(`https://lkval-1259337012.cos.ap-nanjing.myqcloud.com/lkval/main/${this.discussData.images[i].split('.')[0] + '.' + 'webp'}`)
          this.imageList.push(imgNameToUrl(this.discussData.images[i]))
        }
        this.$nextTick(() => {
          new Viewer(document.getElementById('images'))
        });
        // 处理视频
        if (this.discussData.bili_link) {
          const [videoSrc, linkWithSecond] = biliLinkToIframe(this.discussData.bili_link, this.discussData.bili_start_minute, this.discussData.bili_start_second, this.discussData.bili_iframe)
          this.videoSrc = videoSrc
          this.linkWithSecond = linkWithSecond
        }
        // 处理时哪个游戏
        if (this.discussData.game === 1) {
          store.commit('setCurrentPage', 'val')
          localStorage.setItem('currentPage', 'val')
        }
        if (this.discussData.game === 2) {
          store.commit('setCurrentPage', 'cs2')
          localStorage.setItem('currentPage', 'cs2')
        }
      })
    },
    openSetLineupLink() {
      let lineupPath = '/'
      if (store.state.currentPage === 'val') {
        lineupPath = '/'
      }
      if (store.state.currentPage === 'cs2') {
        lineupPath = '/cs2'
      }
      let routeData = this.$router.resolve({path: lineupPath, query: {blogid: this.$route.params.blogId}})
      window.open(routeData.href, '_blank');
    },
    openEditBlog() {
      let routeData = this.$router.resolve({path: '/discuss', query: {blogid: this.$route.params.blogId}})
      window.open(routeData.href, '_blank');
    },
    delLineup() {
      axios({
        method: 'post',
        url: '/api/blogs/del_lineup',
        data: {
          blogId: this.$route.params.blogId
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.$message({
            message: '删除lineup成功',
            type: 'success'
          });
        }
      })
    },
    copyLineupLink() {
      try {
        let value = window.location.href
        var input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.value = value;
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand('copy')
        //移除input标签
        document.body.removeChild(input)
        this.$message({
          message: '复制链接成功',
          type: 'success'
        })
      } catch (e) {
        this.$message.error("复制失败")
      }
    },
    copyLineupID() {
      try {
        let value = this.discussData.id
        var input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.value = value;
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand('copy')
        //移除input标签
        document.body.removeChild(input)
        this.$message({
          message: '复制ID成功',
          type: 'success'
        })
      } catch (e) {
        this.$message.error("复制失败")
      }
    },
    submitComment() {
      // 放到main.js里面
      // String.prototype.trim = function () {
      //   return this.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
      // }
      // if (!this.$store.state.userinfo.figureurl_qq_1) {
      //   return
      // }
      if (this.comment.trim() === '') {
        this.$message.error("评论内容为空")
        return
      }
      axios({
        method: 'post',
        url: '/api/blogs/submit_comment',
        data: {
          blog_id: this.$route.params.blogId,
          comment: this.comment
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.comment = ''
          this.getCommentList(this.$route.params.blogId)
          this.$message({
            message: '评论成功',
            type: 'success'
          });
        } else {
          this.$message.error(r.data.msg);
        }
      })
    },
    getCommentList(blogId) {
      axios({
        method: 'get',
        url: '/api/blogs/get_comment_list',
        params: {
          blog_id: blogId
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.commentList = r.data.list
        }
      })
    },
    checkIsLogin(e) {
      if (!this.$store.state.userinfo.figureurl_qq_1) {
        e.preventDefault()
        this.loginDialogVisible = true
      }
    },
    delComment(itemId) {
      axios({
        method: 'post',
        url: '/api/blogs/del_comment',
        data: {
          obj_id: itemId,
          blog_id: this.$route.params.blogId,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.getCommentList(this.$route.params.blogId)
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
      })
    },
    delBlog() {
      axios({
        method: 'post',
        url: '/api/blogs/del_discuss',
        data: {
          obj_id: this.$route.params.blogId,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
      })
    },
    openQQLogin() {
      localStorage.setItem('urlBeforeLogin', window.location.href)
      window.location = 'https://graph.qq.com/oauth2.0/authorize?response_type=token&client_id=102047093&redirect_uri=https://lkval.com/qqlogin'
    },
  }
}
</script>

<style scoped>
.profile {
  display: flex;
  align-items: center;
  justify-content: inherit;
  margin-bottom: 10px;
}

.profile-middle {
  flex: 1;
}

.profile-time {
  font-size: 12px;
  color: #8e969c;
}

.detail-title {
  font-size: 24px;
  font-weight: 700;
  color: #232426;
  margin-bottom: 10px;
}

.biliVideo {
  margin-top: 5px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%; /* 高度应该是宽度的56% */
}

.biliVideo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.comment {
  display: flex;
}

.comment-left {
  margin-right: 20px;
}

.posting-bottom-bar {
  display: flex;
  margin-top: 5px;
}

.posting-bottom-bar .left {
  display: flex;
  flex-grow: 1;
}

.posting-bottom-bar .left .left-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 15px;
}

.posting-bottom-bar .left .left-item:hover {
  color: #409eff;
  cursor: pointer;
}

.posting-bottom-bar .right {
  display: flex;
  align-items: center;
}

.discuss-data-item {
  padding: 20px;
}

.el-divider--horizontal {
  margin: 0;
}

.header-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-btn-login {
  width: 80px;
  height: 32px;
  border: 1px #0066ff solid;
  border-radius: 3px;
  color: white;
  background: #0066ff;
  cursor: pointer;
}

.fangda {
  cursor: zoom-in;
}
</style>